/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ChangeEvent, FC, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { useFormatDateTime } from 'hooks/useFormatDateTime';
import {
	EApiPooledDepositTransactionStatus,
	EApiTransactionStatus,
	EPendingWithdrawalType,
	IPendingWithdrawal,
} from 'services/api/transactions/types';
import {
	putPendingWithdrawalApproveRequest,
	putPendingWithdrawalCancelRequest,
	putPendingWithdrawalCryptoApproveRequest,
	putPendingWithdrawalCryptoRejectRequest,
	putPendingWithdrawalNoteRequest,
	putPendingWithdrawalRejectRequest,
	putPendingWithdrawalStatusRequest,
} from 'redux/reducers/transactions/reducer';
import { IHistoryFiltersOptionStringValue } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { CheckIcon, CloseIcon, PencilIcon } from 'assets/inline-svg';
import TableItemSelect from 'ui/TableItemSelect/TableItemSelect';
import { roundingNumber } from 'services/utils/roundingNumber';
import { capitalizeWords, shortNotation } from 'services/utils/strings';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'services/utils/notificationContainer';
import moment from 'moment';
import { getNameList } from 'country-list';
import { ICountry } from '../../../../tables/CreateUserTables/UserTable';

const STATUS_TO_EDIT_CRYPTO = [
	{
		id: 1,
		name: 'Approved',
		value: EApiPooledDepositTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Rejected',
		value: EApiPooledDepositTransactionStatus.REJECTED,
	},
	{
		id: 3,
		name: 'Pending',
		value: EApiPooledDepositTransactionStatus.ADMIN_PENDING,
	},
];
const STATUS_TO_EDIT_FIAT = [
	{
		id: 1,
		name: 'Completed',
		value: EApiPooledDepositTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Rejected',
		value: EApiPooledDepositTransactionStatus.REJECTED,
	},
	{
		id: 3,
		name: 'Pending',
		value: EApiPooledDepositTransactionStatus.ADMIN_PENDING,
	},
	{
		id: 4,
		name: 'Cancelled',
		value: EApiPooledDepositTransactionStatus.CANCELED,
	},
];

export interface IPendingWithdrawalTableRow extends IPendingWithdrawal {
	permission: boolean;
	assetType: EPendingWithdrawalType;
}

const PendingWithdrawalTableRow: FC<any> = ({
	id,
	status,
	created_at,
	asset,
	amount,
	type,
	user,
	permission,
	note,
	assetType,
	admin,
	tx_hash,
	admin_id,
	record_type,
	admin_requisite_id,
	fee,
	indicated_amount,
	user_id,
	gross_fee,
	net_fee,
	destination,
	address,
	bank_account,
}) => {
	const dispatch = useDispatch();
	const { path } = useRouteMatch();
	const [countries, setCountries] = useState<ICountry[]>([]);
	const [beneficiaryCountry, setBeneficiaryCountry] = useState<ICountry | null>(null);
	const [bankCountry, setBankCountry] = useState<ICountry | null>(null);

	useLayoutEffect(() => {
		const result = Object.entries(getNameList()).map(([key, value]) => ({
			label: capitalizeWords(key),
			value,
		}));
		setCountries(result);
	}, []);

	useEffect(() => {
		if (bank_account?.beneficiary_country && bank_account?.bank_country) {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			/* fetch(
				'https://valid.layercode.workers.dev/list/countries?format=select&flags=false&value=code',
			)
				.then((response) => response.json())
				.then((data) => {
					setBeneficiaryCountry(
						data.countries.filter(
							(item: ICountry) => item.value === bank_account?.beneficiary_country,
						)[0],
					);
					setBankCountry(
						data.countries.filter((item: ICountry) => item.value === bank_account?.bank_country)[0],
					);
				}); */
			setBeneficiaryCountry(
				countries.filter((item: ICountry) => item.value === bank_account?.beneficiary_country)[0],
			);
			setBankCountry(
				countries.filter((item: ICountry) => item.value === bank_account?.bank_country)[0],
			);
		}
	}, [bank_account, countries]);

	const [date, time] = useFormatDateTime(created_at);
	const statusToEditArray =
		assetType === EPendingWithdrawalType.FIAT ? STATUS_TO_EDIT_FIAT : STATUS_TO_EDIT_CRYPTO;

	const filterStatusArrayToEdit = (currentStatus: EApiPooledDepositTransactionStatus | string) => {
		const statusFinal = currentStatus === 'pending' ? 'admin_pending' : currentStatus;
		return statusToEditArray.filter((el) => el.value !== statusFinal);
	};
	const findEditedStatus = (currentStatus: string) => {
		const statusFinal = currentStatus === 'pending' ? 'admin_pending' : currentStatus;
		return statusToEditArray.find((el) => el.value === statusFinal) || statusToEditArray[0];
	};

	const [editedNote, setEditedNote] = useState(note);
	const handleChangeEditedNote = (event: ChangeEvent<HTMLInputElement>) => {
		setEditedNote(event.target.value || null);
	};

	const [editedStatus, setEditedStatus] = useState<IHistoryFiltersOptionStringValue>({
		id: 0,
		value: EApiPooledDepositTransactionStatus.ADMIN_PENDING,
		name: 'Pending',
	});
	const [statusArrayToEdit, setStatusArrayToEdit] = useState<IHistoryFiltersOptionStringValue[]>(
		filterStatusArrayToEdit(status),
	);
	const handleEditStatus = (activeElement: IHistoryFiltersOptionStringValue) => {
		setEditedStatus(activeElement);
		setStatusArrayToEdit(filterStatusArrayToEdit(activeElement.value));
	};

	const [editMode, setEditMode] = useState(false);
	const enableEditMode = () => setEditMode(true);
	const disableEditMode = () => setEditMode(false);

	const handleEdit = () => {
		if (record_type === 'transaction_fiat') {
			const data: any = {
				admin_requisite_id,
				date: moment(new Date()).format('YYYY-MM-DD'),
				fee,
				indicated_amount,
				note: editedNote || '',
				status: editedStatus.value.toLowerCase(),
			};
			dispatch(putPendingWithdrawalStatusRequest({ type: assetType, id, payloadData: data }));
		} else if (record_type === 'transaction_crypto') {
			if (editedStatus.value === EApiPooledDepositTransactionStatus.FAILED) {
				dispatch(putPendingWithdrawalCryptoRejectRequest({ type: assetType, id }));
			}
			if (editedStatus.value === EApiPooledDepositTransactionStatus.REJECTED) {
				dispatch(putPendingWithdrawalCryptoRejectRequest({ type: assetType, id }));
			}
			if (editedStatus.value === EApiPooledDepositTransactionStatus.COMPLETED) {
				dispatch(putPendingWithdrawalCryptoApproveRequest({ type: assetType, id }));
			}
		} else {
			if (editedStatus.value === EApiPooledDepositTransactionStatus.FAILED) {
				dispatch(putPendingWithdrawalRejectRequest({ type: assetType, id }));
			}
			if (editedStatus.value === EApiPooledDepositTransactionStatus.REJECTED) {
				dispatch(putPendingWithdrawalRejectRequest({ type: assetType, id }));
			}
			if (editedStatus.value === EApiPooledDepositTransactionStatus.COMPLETED) {
				dispatch(putPendingWithdrawalApproveRequest({ type: assetType, id }));
			}
			if (editedStatus.value === EApiPooledDepositTransactionStatus.CANCELED) {
				dispatch(putPendingWithdrawalCancelRequest({ type: assetType, id }));
			}
			if (editedNote !== note) {
				dispatch(
					putPendingWithdrawalNoteRequest({
						type: assetType,
						data: { transaction_id: id, note: editedNote || '' },
					}),
				);
			}
		}
		disableEditMode();
	};
	const handleDisableEditMode = () => {
		setEditedStatus(findEditedStatus(status));
		disableEditMode();
	};

	const convertStatusValue = (currentStatus: EApiTransactionStatus) => {
		switch (currentStatus) {
			case EApiTransactionStatus.FAILED:
				return 'Failed';
			case EApiTransactionStatus.CANCELED:
				return 'Failed';
			case EApiTransactionStatus.REJECTED:
				return 'Failed';
			case EApiTransactionStatus.ADMIN_PENDING:
				return 'Pending';
			case EApiTransactionStatus.PENDING_SIGNING:
				return 'Pending Signing';
			case EApiTransactionStatus.SUCCESS:
				return 'Completed';
			case EApiTransactionStatus.COMPLETED:
				return 'Completed';
			case EApiTransactionStatus.BROADCASTING:
				return 'Broadcasting';
			default:
				return `${currentStatus[0].toUpperCase()}${currentStatus.slice(1)}`;
		}
	};
	const [full, from, to] = type.match(/From\s+(.*?)\s+To\s+(.*)/);

	const handleCopy = () => {
		notificationContainer('Сopied to clipboard.', 'info');
	};

	let userId;
	if (user_id && !admin_id) {
		userId = user_id;
	}
	if (!user_id && admin_id) {
		userId = admin_id;
	}
	if (user_id && admin_id) {
		userId = user_id;
	}

	return (
		<div className={`tr ${editMode ? 'tr--editable' : ''}`}>
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<p className="td-name--flex">{date}</p>
				<span className="td-more-info">{time.replaceAll('-', ':')}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p>{userId}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p className="td-name--flex">{asset.code.toUpperCase()}</p>
				<span className="td-more-info">{asset?.name}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Gross</p>
				{record_type === 'pool_transaction' ? (
					<>
						<p>{roundingNumber(amount, asset.code)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				) : (
					<>
						<p>{roundingNumber(gross_fee, asset.code)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>{roundingNumber(fee, asset.code)}</p>
				<span className="td-more-info">{asset.code.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Net</p>
				{record_type === 'pool_transaction' ? (
					<>
						<p>{roundingNumber(amount, asset.code)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				) : (
					<>
						<p>{roundingNumber(net_fee, asset.code)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				)}
			</div>

			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p
					className={
						assetType === EPendingWithdrawalType.CRYPTO ? 'td--width-150' : 'td--width-130'
					}
				>
					From: <span className="td-bold-text">{from}</span>
				</p>
				<p
					className={
						assetType === EPendingWithdrawalType.CRYPTO ? 'td--width-150' : 'td--width-130'
					}
				>
					To: <span className="td-bold-text">{to}</span>
				</p>
			</div>

			{assetType === EPendingWithdrawalType.FIAT && (
				<div className="td">
					<p className="td-hidden-name">Destination</p>
					<p>
						<span className="tooltip-wrap">
							{bank_account?.bank_name || '-'}

							{bank_account?.bank_name && (
								<span className="tooltip-item">
									<span className="tooltip-item__title">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="15"
											height="14"
											viewBox="0 0 15 14"
											fill="none"
										>
											<path
												d="M7.20003 12.8307C8.81085 12.8307 10.2692 12.1778 11.3248 11.1222C12.3804 10.0665 13.0334 8.60821 13.0334 6.9974C13.0334 5.38658 12.3804 3.92825 11.3248 2.8726C10.2692 1.81698 8.81085 1.16406 7.20003 1.16406C5.58922 1.16406 4.13088 1.81698 3.07524 2.8726C2.01962 3.92825 1.3667 5.38658 1.3667 6.9974C1.3667 8.60821 2.01962 10.0665 3.07524 11.1222C4.13088 12.1778 5.58922 12.8307 7.20003 12.8307Z"
												stroke="#0071AB"
												strokeLinejoin="round"
											/>
											<path
												d="M7.20036 3.71094C7.32692 3.71094 7.42952 3.81354 7.42952 3.9401C7.42952 4.06667 7.32692 4.16927 7.20036 4.16927C7.0738 4.16927 6.97119 4.06667 6.97119 3.9401C6.97119 3.81354 7.0738 3.71094 7.20036 3.71094Z"
												fill="#0071AB"
												stroke="#0071AB"
											/>
											<path
												d="M7.34603 9.91927V5.83594H7.05436H6.7627"
												stroke="#0071AB"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M6.3252 9.91406H8.36686"
												stroke="#0071AB"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</span>
									<span className="tooltip tooltip--width-720 tooltip--bottom">
										<span className="bank-info">
											<span className="bank-info bank-info--title">{bank_account?.bank_name}</span>
											<span className="bank-info bank-info--list-wrapper">
												<span className="bank-info bank-info--list">
													<span className="bank-info bank-info--item">
														<span>Beneficiary Name</span>
														<p>{bank_account?.beneficiary_name}</p>
													</span>
													<span className="bank-info bank-info--item">
														<span>Beneficiary Address</span>
														<p>{bank_account?.beneficiary_address}</p>
													</span>
													<span className="bank-info bank-info--item">
														<span>Beneficiary Country</span>
														<p>{beneficiaryCountry?.label || '-'}</p>
													</span>
													<span className="bank-info bank-info--item">
														<span>Bank Name</span>
														<p>{bank_account?.bank_name}</p>
													</span>
												</span>
												<span className="bank-info-list bank-info--list">
													<span className="bank-info  bank-info--item">
														<span>Bank Address</span>
														<p>{bank_account?.bank_address}</p>
													</span>
													<span className="bank-info  bank-info--item">
														<span>Bank Country</span>
														<p>{bankCountry?.label || '-'}</p>
													</span>
													<span className="bank-info  bank-info--item">
														<span>SWIFT / BIC</span>
														<p>{bank_account?.swift_bic}</p>
													</span>
													<span className="bank-info  bank-info--item">
														<span>Account Number</span>
														<p>{bank_account?.account_number}</p>
													</span>
												</span>
												<span className="bank-info  bank-info--text">
													<span>Notes</span>
													<p>{bank_account?.note}</p>
												</span>
											</span>
										</span>
									</span>
								</span>
							)}
						</span>
						<span className="td-more-info">{bank_account?.account_number}</span>
					</p>
				</div>
			)}

			{assetType === EPendingWithdrawalType.CRYPTO && (
				<>
					<p className="td-hidden-name">Destination</p>
					<p className="copy-button">
						<span className="copy-button__text copy-button__text--max-width">
							{shortNotation(destination || address?.address, 4, 4) || '-'}
						</span>
						{!!destination && (
							<CopyToClipboard text={destination.toString()} onCopy={handleCopy}>
								<button type="button">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="15"
										height="14"
										viewBox="0 0 15 14"
										fill="none"
									>
										<path
											d="M3.29199 12.8151C3.05866 12.8151 2.85449 12.7276 2.67949 12.5526C2.50449 12.3776 2.41699 12.1734 2.41699 11.9401V3.14635H3.29199V11.9401H10.2045V12.8151H3.29199ZM5.04199 11.0651C4.80866 11.0651 4.60449 10.9776 4.42949 10.8026C4.25449 10.6276 4.16699 10.4234 4.16699 10.1901V2.02344C4.16699 1.7901 4.25449 1.58594 4.42949 1.41094C4.60449 1.23594 4.80866 1.14844 5.04199 1.14844H11.4587C11.692 1.14844 11.8962 1.23594 12.0712 1.41094C12.2462 1.58594 12.3337 1.7901 12.3337 2.02344V10.1901C12.3337 10.4234 12.2462 10.6276 12.0712 10.8026C11.8962 10.9776 11.692 11.0651 11.4587 11.0651H5.04199ZM5.04199 10.1901H11.4587V2.02344H5.04199V10.1901Z"
											fill="#777E90"
										/>
									</svg>
								</button>
							</CopyToClipboard>
						)}
						{!!address?.address && (
							<CopyToClipboard text={address?.address.toString()} onCopy={handleCopy}>
								<button type="button">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="15"
										height="14"
										viewBox="0 0 15 14"
										fill="none"
									>
										<path
											d="M3.29199 12.8151C3.05866 12.8151 2.85449 12.7276 2.67949 12.5526C2.50449 12.3776 2.41699 12.1734 2.41699 11.9401V3.14635H3.29199V11.9401H10.2045V12.8151H3.29199ZM5.04199 11.0651C4.80866 11.0651 4.60449 10.9776 4.42949 10.8026C4.25449 10.6276 4.16699 10.4234 4.16699 10.1901V2.02344C4.16699 1.7901 4.25449 1.58594 4.42949 1.41094C4.60449 1.23594 4.80866 1.14844 5.04199 1.14844H11.4587C11.692 1.14844 11.8962 1.23594 12.0712 1.41094C12.2462 1.58594 12.3337 1.7901 12.3337 2.02344V10.1901C12.3337 10.4234 12.2462 10.6276 12.0712 10.8026C11.8962 10.9776 11.692 11.0651 11.4587 11.0651H5.04199ZM5.04199 10.1901H11.4587V2.02344H5.04199V10.1901Z"
											fill="#777E90"
										/>
									</svg>
								</button>
							</CopyToClipboard>
						)}
					</p>
				</>
			)}
			{assetType === EPendingWithdrawalType.CRYPTO && (
				<>
					<p className="td-hidden-name">Tx Hash</p>
					<p className="copy-button">
						<span className="copy-button__text copy-button__text--max-width">
							{shortNotation(tx_hash, 4, 4) || '-'}
						</span>
						{!!tx_hash && (
							<CopyToClipboard text={tx_hash.toString()} onCopy={handleCopy}>
								<button type="button">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="15"
										height="14"
										viewBox="0 0 15 14"
										fill="none"
									>
										<path
											d="M3.29199 12.8151C3.05866 12.8151 2.85449 12.7276 2.67949 12.5526C2.50449 12.3776 2.41699 12.1734 2.41699 11.9401V3.14635H3.29199V11.9401H10.2045V12.8151H3.29199ZM5.04199 11.0651C4.80866 11.0651 4.60449 10.9776 4.42949 10.8026C4.25449 10.6276 4.16699 10.4234 4.16699 10.1901V2.02344C4.16699 1.7901 4.25449 1.58594 4.42949 1.41094C4.60449 1.23594 4.80866 1.14844 5.04199 1.14844H11.4587C11.692 1.14844 11.8962 1.23594 12.0712 1.41094C12.2462 1.58594 12.3337 1.7901 12.3337 2.02344V10.1901C12.3337 10.4234 12.2462 10.6276 12.0712 10.8026C11.8962 10.9776 11.692 11.0651 11.4587 11.0651H5.04199ZM5.04199 10.1901H11.4587V2.02344H5.04199V10.1901Z"
											fill="#777E90"
										/>
									</svg>
								</button>
							</CopyToClipboard>
						)}
					</p>
				</>
			)}
			{/* <div className="td">
				<p className="td-hidden-name">Admin Notes</p>
				{editMode ? (
					<div className="input">
						<div className="input-wrapper">
							<input
								type="text"
								placeholder="Notes"
								value={editedNote || ''}
								onChange={handleChangeEditedNote}
								className="input-item input-item--type-td"
							/>
						</div>
					</div>
				) : (
					<div className="tooltip-item-note">
						<p className="tooltip-item-text">{note ? `${note?.slice(0, 10) as string}...` : '-'}</p>
						<div className="tooltip-note">
							<p>{note || '-'}</p>
							<i />
						</div>
					</div>
				)}
			</div> */}
			{assetType === EPendingWithdrawalType.CRYPTO ? (
				<div className="td">
					<p className="td-hidden-name">Tx ID</p>
					{record_type === 'pool_transaction' ? (
						<p>{id}</p>
					) : (
						<Link
							to={{
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								pathname: `${path}/${id}`,
								state: { fromPendingWithdrawalCrypto: true, txId: id, userId: user_id },
							}}
						>
							{id}
						</Link>
					)}
				</div>
			) : (
				<div className="td">
					<p className="td-hidden-name">Tx ID</p>
					{record_type === 'pool_transaction' ? (
						<p>{id}</p>
					) : (
						<Link
							to={{
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								pathname: `${path}/${id}`,
								state: { fromPendingWithdrawalFiat: true, txId: id, userId: user_id },
							}}
						>
							{id}
						</Link>
					)}
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">Status</p>
				{/* {editMode && status === EApiTransactionStatus.ADMIN_PENDING ? ( */}
				{editMode ? (
					<TableItemSelect
						activeElement={editedStatus}
						selectArray={statusArrayToEdit}
						onChange={handleEditStatus}
					/>
				) : (
					<span
						className={
							status === EApiTransactionStatus.ADMIN_PENDING
								? 'pending-status'
								: `${String(status)}-status`
						}
					>
						{convertStatusValue(status)}
					</span>
				)}
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				{permission && editMode && (
					<div className="table-buttons flex-e">
						<button
							type="button"
							className="btn btn--icon btn--check"
							onClick={handleEdit}
							disabled={
								editedStatus.value === EApiPooledDepositTransactionStatus.ADMIN_PENDING &&
								note === editedNote
							}
						/>
						<button
							type="button"
							className="btn btn--icon btn--cancel"
							onClick={handleDisableEditMode}
						/>
					</div>
				)}
				{permission && !editMode && (
					<>
						{assetType === 'fiat' ? (
							<>
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label, react/self-closing-comp */}
								<button
									type="button"
									className="btn btn--icon btn--edit"
									onClick={enableEditMode}
									// disabled={status !== EApiTransactionStatus.ADMIN_PENDING}
								></button>
							</>
						) : (
							<>
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label, react/self-closing-comp */}
								<button
									type="button"
									className="btn btn--icon btn--edit"
									onClick={enableEditMode}
									// disabled={status !== EApiTransactionStatus.ADMIN_PENDING}
									disabled={
										status === EApiTransactionStatus.PENDING ||
										status === EApiTransactionStatus.BROADCASTING ||
										status === EApiTransactionStatus.PENDING_SIGNING
									}
								></button>
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default PendingWithdrawalTableRow;
